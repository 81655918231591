<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <span class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block">Detail Transaksi</span>
      </div>
    </div>
    <div class="cotainer-standar container-res-1440 mt-6">
      <div>
        <b-card no-body>
          <b-tabs pills card vertical>
            <b-tab active>
              <template v-slot:title>
                <!-- <span class="width-100 pt-2 pb-2 font-14 font-weight-7 text-primary">Umroh</span> -->
                <div class="width-100 pt-2 pb-2 mt-4 font-14">
                  <div class="finding-tab">
                    <img src="https://icons-for-free.com/iconfiles/png/512/dollar+funds+hand+payment+icon+icon-1320086640827007356.png" style="width:45px" alt />
                    <span>Pembayaran</span>
                  </div>
                </div>
              </template>
              <b-card-text>
                <div class="col-lg-12">
                    <div class="purchases-list">
                        <div class="purchases-list-header">
                            <div class="purchases-list-header-date">
                                <p class="text-header small">Tanggal</p>
                            </div>
                            <div class="purchases-list-header-details">
                                <p class="text-header small">Kode Pembayaran</p>
                            </div>
                            <div class="purchases-list-header-info">
                                <p class="text-header small">Informasi Tambahan</p>
                            </div>
                            <div class="purchases-list-header-price">
                                <p class="text-header small"></p>
                            </div>
                            <div class="purchases-list-header-download">
                                <p class="text-header small"></p>
                            </div>
                        </div>
                        <div class="purchase-item" :key="i" v-for="(items, i) in invoiceList">
                            <div class="purchase-item-date">
                                <p>{{getDate(items.created)}}</p>
                            </div>
                            <div class="purchase-item-details" style="padding-top: 25px;">
                                <!-- ITEM PREVIEW -->
                                <div class="item-preview" style="padding: 0px;">
                                    <p class="text-header">{{items.booking_number}}</p>
                                </div>
                                <!-- /ITEM PREVIEW -->
                            </div>
                            <div class="purchase-item-info">
                                <p class="category primary">{{items.status_booking}}</p>
                                <p><span class="light">Methode Pembayaran: </span></p>
                                <p><span class="light">Waktu Transaksi : </span></p>
                            </div>
                            <div class="purchase-item-price">
                                <p class="price"><span>Rp.</span>{{ items.room_price | numeralFormat('0,0[.]00') }}</p>
                            </div>
                            <div class="purchase-item-download text-center" v-if="items.status_booking != 'paid'">
                                <a :href="items.payment_url" target="_blank" class="btn btn-sm bg-warning text-white">Bayar</a>
                            </div>
                            <div class="purchase-item-download text-center" v-else>
                              <span class="category primary">Done</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">                        
                        <div class="col-md-12 row">
                            <div class="col-md-6"></div>
                            <div class="col-md-3 text-right">
                                <span class="font-weight-6 text-primary">Total Harga</span>
                            </div>
                            <div class="col-md-3 text-right">
                                <span class="d-inline-block font-16 text-warning font-weight-7">
                                    {{ hargaPaket | numeralFormat('0,0[.]00') }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12 row mt-3">
                            <div class="col-md-6"></div>
                            <div class="col-md-3 text-right">
                                <span class="font-weight-6 text-primary">Total Sudah Bayar</span>
                            </div>
                            <div class="col-md-3 text-right">
                                <span class="d-inline-block font-16 text-warning font-weight-7">
                                    {{ totalPaid | numeralFormat('0,0[.]00') }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12 row mt-3">
                            <div class="col-md-6"></div>
                            <div class="col-md-3 text-right">
                                <span class="font-weight-6 text-primary">Total Belum Bayar</span>
                            </div>
                            <div class="col-md-3 text-right">
                                <span class="d-inline-block font-16 text-warning font-weight-7">
                                    {{ hargaPaket - totalPaid | numeralFormat('0,0[.]00') }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12 row mt-3">
                            <div class="col-md-12 text-right">
                              <button class="btn btn-sm bg-warning text-white" @click.prevent="OpenTambahPembayaran" :disabled="disableButton == true">Tambah pembayaran baru</button>
                            </div>
                        </div>
                    </div>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

      <b-modal id="modal-Success" hide-footer no-close-on-backdrop centered title>
        <iframe class="embed-responsive-item" :src="paymentUrl" width="100%" height="500" allowfullscreen></iframe>
      </b-modal>

      <b-modal id="modal-tambah-pembayaran" title="Silahkan masukan nominal" hide-footer no-close-on-backdrop centered>
        <div class="col-md-12 pb-6 text-center">
          <!-- <span class="text-grey font-weight-5 font-16 d-block mb-20"></span> -->
          <div class="form-group mt-1">
              <input autocomplete="off" type="text" placeholder="Contoh: Rp. 500.000" 
              v-model="nominalPembayaran" 
              class="input-same-select pos-relative pristine touched is-valid" data-vv-id="3" aria-required="true" aria-invalid="false">
              <!-- <span class="eror text-danger font-10"></span> -->
              <div v-show="showTextNom">
                <span class="d-block w-100 text-danger font-12">{{messageText}}</span>
              </div>
          </div>
          <span @click.prevent="postPembayaranBaru()"
            class="d-block width-50 bg-warning pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto cursor-pointer"
          >Lanjutkan pembayaran</span>
        </div>
      </b-modal>

      <b-modal id="modal-succes-pembayaran"   hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <img width="150px" class="margin-auto pb-10 pt-4" src="@/assets/images/icon/check.svg" alt />
        <span class="text-primary font-weight-6 font-18">Pesanan Anda Sedang di Proses</span>
        <br />
        <span class="text-grey font-weight-5 font-16 d-block mb-20">Silahkan lanjutkan Pembayaran</span>
        <span @click.prevent="PostData(trasaction_payment_url)"
          class="d-block width-50 bg-warning pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15   cursor-pointer"
        >Lanjutkan pembayaran</span>
      </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import * as moment from 'moment';
export default {
  components: {
    // flatPickr  
  },
  data() {
    return {
      LoginCheck: false,
      invoiceList: [],
      userData: this.$store.state.userdata,
      date: new Date(),
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },
      hargaPaket:0,
      totalPaid:0,
      paymentUrl:'',
      disableButton: true,
      nominalPembayaran:'',
      showTextNom:false,
      trasaction_payment_url:'',
      messageText:''
    };
  },
  watch: {
    nominalPembayaran(val) {
      let result = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      this.$emit('input', val.replace(/[^0-9]/g, ''))
      this.$nextTick(() => this.nominalPembayaran = result)
    },
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getInvoiceData();
    this.getBookingData();
  },
  methods: {
    getInvoiceData() {
        this.axios
        .get("https://api.realtravel.co.id/transaction/booking/invoice?booking_id="+this.$route.params.id, {
          headers: {
            "token": this.userData.token,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(response => {
            let res = response.data;
            let data = res.response.data;
            for (let i = 0; i < data.length; i++) {
              this.invoiceList.push({
                  id: data[i].id,
                  name: data[i].status_active,
                  created: data[i].invoice_date,
                  booking_number: data[i].invoice_number,
                  room_price: data[i].payment_amount,
                  status_booking:data[i].payment_status,
                  payment_url: data[i].payment_url
              })
              if(data[i].payment_status == "paid"){
                this.totalPaid += parseFloat(data[i].payment_amount);
                this.disableButton = false;
              }else{
                this.disableButton = true;
              }
            }
        })
        .catch(error => {
          error;
        }); 
    },
    getBookingData() {
        this.axios
        .get("https://api.realtravel.co.id/transaction/booking?id="+this.$route.params.id, {
            headers: {
            "token": this.userData.token,
            "Content-Type": "application/x-www-form-urlencoded"
            }
        })
        .then(response => {
            let res = response.data;
            let data = res.response.data;
            this.hargaPaket = data[0].total_price;
        })
        .catch(error => {
          error;
        }); 
    },
    OpenPayment(val){
        // alert(val)
        this.paymentUrl = val;
        setTimeout(() => {              
            this.$bvModal.show("modal-Success");
        }, 500);
    },
    OpenTambahPembayaran(){
        // setTimeout(() => {              
          this.$bvModal.show("modal-tambah-pembayaran");
        // }, 500);
    },
    postPembayaranBaru(){
      let nominalStr = this.nominalPembayaran.split('.').join('');
      let sisaPembayaran = this.hargaPaket - this.totalPaid;
      // if (nominalStr > sisaPembayaran) {
      //   console.log('ewe')
      // } else {
      //   console.log('jero')        
      // }
      // console.log(nominalStr)
      let param = new FormData();
      console.log(sisaPembayaran)
      param.append("booking_id", this.$route.params.id);
      param.append("amount", nominalStr);
      if (nominalStr < 500000) {
        this.messageText = '*Minimal pembayaran tidak boleh kurang dari 500.000';
        this.showTextNom = true;
      } else {
        if (nominalStr > sisaPembayaran) {
          this.messageText = '*Jumlah pembayaran tidak boleh melebihi sisa pembayaran.';
          this.showTextNom = true;
        }else{
          this.axios
            .post("https://api.realtravel.co.id/transaction/booking/invoice", param, {
                headers: {
                  "token": this.userData.token,
                  "Content-Type": "application/x-www-form-urlencoded"
                }
              })
            .then(response => {
              let res = response
              let data = res.data;
              if (data.code == 200) {
                this.trasaction_payment_url = data.response.data.payment_url;
                this.$bvModal.hide("modal-tambah-pembayaran");
                this.$bvModal.show("modal-succes-pembayaran");
              } else {
                alert('Terjadi kesalahan')           
                this.$bvModal.hide("modal-tambah-pembayaran");
                window.location.reload()
              }
          });
        }
      }
    },
    PostData(newVal){
      this.$bvModal.hide("modal-succes-pembayaran");
      window.open(newVal, '_blank');
    },
    getDate(val){
      moment.locale('ID'); 
      return moment(val).format('LL')
    },
  }
};
</script>